@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.dots {
  display: inline-block;
}

.dots span {
  display: inline-block;
  width: 4px;
  height: 4px;
  margin: 0 2px;
  background-color: currentColor;
  border-radius: 50%;
  animation: bounce 1.5s infinite ease-in-out;
}

.dots span:nth-child(1) {
  animation-delay: 0.1s;
}

.dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.dots span:nth-child(3) {
  animation-delay: 0.3s;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.loading-text .ellipsis {
  display: inline-block;
}

.loading-text .ellipsis span {
  display: inline-block;
  animation: bounce 1.4s infinite;
}

.loading-text .ellipsis span:nth-child(1) {
  animation-delay: 0s;
}

.loading-text .ellipsis span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-text .ellipsis span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
\.wave {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.wave span {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 3px;
  background-color: #65e8b5;
  border-radius: 50%;
  animation: wave 1.2s infinite;
}

.wave span:nth-child(1) {
  animation-delay: 0s;
}

.wave span:nth-child(2) {
  animation-delay: 0.2s;
}

.wave span:nth-child(3) {
  animation-delay: 0.4s;
}

.wave span:nth-child(4) {
  animation-delay: 0.6s;
}

.wave span:nth-child(5) {
  animation-delay: 0.8s;
}

@keyframes wave {
  0%,
  100% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.4);
    opacity: 1;
  }
}
